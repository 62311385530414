// src/api/api.js
import axiosInstance from './axiosInstance';

// 客户查询接口
const queryUsers = (params) => {
  return axiosInstance.get('/erp/orders', {params});
};

// 客户信息更新接口
const queryUserUpdate = (params) => {
  return axiosInstance.post('/erp/update', params);
};

// 客户订单列表接口
const queryOrderList = (params) => {
  return axiosInstance.get('/erp/orderlist', {params});
};

//物流列表接口
const queryLogistics = (params) => {
  return axiosInstance.get('/erp/express', {params});
};

//产品介绍接口
const queryIntroduction = (params) => {
  return axiosInstance.get('/erp/introduction', {params});
};

//产品定价接口
const queryPrice = (params) => {
  return axiosInstance.get('/erp/price', {params});
};
//分销店铺接口
const queryShop = (params) => {
  return axiosInstance.get('/erp/shop', {params});
};

const queryAfterSaleDetail = (params) => {
  return axiosInstance.get('/erp/orders', {params});
}

export {
  queryUsers,
  queryUserUpdate,
  queryOrderList,
  queryLogistics,
  queryIntroduction,
  queryPrice,
  queryShop,
  queryAfterSaleDetail
};