<template>
  <div id="app">
    <router-view></router-view>
    <van-tabbar v-model="active">
      <van-tabbar-item to="/" icon="friends-o">用户信息</van-tabbar-item>
      <van-tabbar-item to="/product-introduction" icon="gift-o"
        >产品介绍</van-tabbar-item
      >
      <van-tabbar-item to="/product-price" icon="balance-list-o"
        >价格标准</van-tabbar-item
      >
      <!-- <van-tabbar-item to="/product-introduction" icon="service-o"
        >常用话术</van-tabbar-item
      > -->
      <van-tabbar-item to="/product-shop" icon="home-o"
        >分销店铺</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  width: 375px;
  height: 812px;
  overflow: hidden; /* 防止内容超出容器 */
  box-sizing: border-box; /* 确保 padding 和 border 不会增加元素的总宽度和高度 */
}
</style>
