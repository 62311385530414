<template>
  <div class="after-sale-detail">
    <van-nav-bar
      title="售后详情"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <p>关联订单: {{ orderId }}</p>
    <div class="sale-content">
      <div class="sale-detail">
        <div v-if="!order.refund_info">
          <p>暂无售后单</p>
        </div>
        <div v-else>
          <h3>售后单列表</h3>
          <div v-for="(item, index) in order.refund_info" :key="index">
            <div class="refund-order">
              <div class="header">
                <p>
                  售后类型:
                  {{ returnTypes[item.oln_type] }}
                </p>
              </div>
              <van-collapse v-model="activeNames">
                <van-collapse-item
                  :title="'售后原因：' + item.ol_reason"
                  :name="'refund_reason' + index"
                >
                  <p>{{ item.ol_desc }}</p>
                </van-collapse-item>
              </van-collapse>
              <div class="details">
                <p>申请时间: {{ formatTime(item.apply_time) }}</p>
              </div>
              <van-collapse v-model="activeNames">
                <van-collapse-item
                  title="原订单备注"
                  :name="'origin_remark' + index"
                >
                  <p>{{ item.origin_remark }}</p>
                </van-collapse-item>
              </van-collapse>
            </div>
          </div>
        </div>
      </div>
      <div class="sale-order">
        <div v-if="!order.refund_orders">
          <p>暂无售后订单</p>
        </div>
        <div v-else>
          <h3>售后订单列表</h3>
          <div v-for="(item, index) in order.refund_orders" :key="index">
            <div class="refund-order">
              <div class="order-title">
                <span class="product-name">
                  <span>{{
                    item.goods.map((good) => good.oln_item_name).join(" | ")
                  }}</span>
                </span>
                <span class="order-status">{{
                  statusMap[item.oln_status] || "其他"
                }}</span>
              </div>
              <hr class="separator" />
              <div class="order-details">
                <div class="left-details">
                  <div>店铺名:{{ item.shop_name }}</div>
                  <div>物流单号:{{ item.express_code }}</div>
                </div>
              </div>
              <div class="order-timings">
                <van-row>
                  <!-- <van-col
                  span="12"
                  v-if="item.pay_time"
                  class="custom-van-grid-item"
                  >下单: {{ formatTime(item.pay_time) }}</van-col
                > -->
                  <van-col
                    span="24"
                    v-if="item.send_time"
                    class="custom-van-grid-item"
                    >发货: {{ formatTime(item.send_time) }}</van-col
                  >
                </van-row>
              </div>
              <div v-if="item.remark">
                <van-collapse v-model="activeNames">
                  <van-collapse-item title="备注" :name="'remark' + index">
                    <p>{{ item.remark }}</p>
                  </van-collapse-item>
                </van-collapse>
              </div>

              <div v-if="item.express_code">
                <hr class="separator" />
                <van-row justify="space-around">
                  <van-col span="24">
                    <van-button
                      class="van-buttons"
                      v-if="item.express_code"
                      type="primary"
                      @click="viewLogistics(item)"
                    >
                      查看物流
                    </van-button>
                  </van-col>
                </van-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showFailToast, showToast, showLoadingToast, closeToast } from "vant";
import { queryAfterSaleDetail } from "@/api/api"; // 假设有一个API来获取售后单详情

export default {
  name: "AfterSaleDetail",
  data() {
    return {
      order: {},
      refund_info: [],
      activeNames: [],
      returnTypes: {
        0: "未知",
        1: "退款",
        2: "退货",
        3: "换货",
        4: "补发",
        5: "维修",
      },
      statusMap: {
        0: "未建交易",
        1: "等待付款",
        2: "等待发货",
        3: "已完成",
        4: "已关闭",
        5: "等待签收",
        6: "已签收",
      },
      orderId: "",
      tradeNo: "",
    };
  },
  created() {},
  mounted() {
    const { orderId, tradeNo } = this.$route.query;
    if (orderId && tradeNo) {
      this.orderId = orderId;
      this.tradeNo = tradeNo;
      this.fetchAfterSaleDetail(tradeNo);
    } else {
      console.error("缺少必要的查询参数");
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async fetchAfterSaleDetail(tradeNo) {
      showLoadingToast({
        message: "加载中...",
        forbidClick: true,
      });
      try {
        const params = {
          order_id: tradeNo,
        };
        const response = await queryAfterSaleDetail(params);
        if (response.code === 200) {
          this.order = response.data;
          console.log(this.order.refund_orders);
          // if (this.order.refund_info && this.order.refund_info.length > 0) {
          //   this.refund_info = this.order.refund_info[0];
          // }
          // console.log(this.order);
          closeToast();
        } else {
          showFailToast("加载失败");
        }
      } catch (error) {
        showToast({
          message: "请求出错，请稍后再试",
        });
      }
    },
    formatTime(time) {
      if (!time) return "";
      const date = new Date(time);
      return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    },
    viewLogistics(order) {
      this.$router.push({
        path: "/logistics-list",
        query: { orderId: order.order_id, expressCode: order.express_code },
      });
    },
  },
};
</script>

<style scoped>
.after-sale-detail {
  padding: 20px;
}

.sale-content {
  height: calc(100vh - 180px); /* 根据实际情况调整高度 */
  overflow-y: auto; /* 启用垂直滚动 */
}

.sale-detail {
  border: 1px solid #ccc; /* 增加边框 */
  padding: 20px;
  border-radius: 8px; /* 可选：增加圆角 */
}

.sale-detail h3 {
  margin-top: 0;
}

.sale-detail p {
  margin-top: 0;
  margin-bottom: 0;
}

.header {
  margin-bottom: 10px;
}

.header h1 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: left; /* 文字靠左对齐 */
  margin-top: 0px;
}

.header p {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left; /* 文字靠左对齐 */
  margin-top: 0px;
}

.van-collapse-item__content p {
  font-size: 14px; /* 调整折叠内容的文字大小 */
  text-align: left; /* 文字靠左对齐 */
}

.details {
  margin-top: 10px;
}

.details p {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left; /* 文字靠左对齐 */
  margin-top: 0px;
}

.sale-order {
  border: 1px solid #ccc; /* 增加边框 */
  padding: 20px;
  border-radius: 8px; /* 可选：增加圆角 */
  margin-top: 20px; /* 可选：增加间距 */
}

.sale-order h3 {
  margin-top: 0;
}

.sale-order p {
  margin-top: 0;
  margin-bottom: 0;
}

.refund-order {
  border: 1px solid #ccc; /* 增加边框 */
  padding: 10px;
  border-radius: 4px; /* 可选：增加圆角 */
  margin-bottom: 10px; /* 可选：增加间距 */
}
.order-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333;
}
.product-name {
  /* width: 70%; */
  font-weight: bold;
  text-align: left;
  font-size: 14px;
}
.order-status {
  color: #07c160;
  width: 58px;
  font-size: 14px;
}

.order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.left-details {
  font-size: 14px;
  color: #666;
  text-align: left;
  width: 100%;
}

.order-timings {
  font-size: 14px;
  color: #666;
}

.separator {
  border: none;
  border-top: 1px solid #ebedf0;
  margin: 10px 0;
}
.custom-van-grid-item {
  font-size: 14px;
}
</style>
