<template>
  <div class="logistics-list">
    <van-nav-bar
      title="物流列表"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />

    <div class="logistics-info">
      <div class="order-id">订单号: {{ orderId }}</div>
      <div class="logistics-id">物流单号: {{ logisticsId }}</div>
    </div>

    <div class="scrollable-steps">
      <van-steps direction="vertical" :active="0" active-color="#07c160">
        <van-step v-for="(step, index) in logisticsSteps" :key="index">
          <h3>{{ step.context }}</h3>
          <!-- <p>{{ step.description }}</p> -->
          <p>{{ formatTime(step.time) }}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { showFailToast, showLoadingToast, closeToast } from "vant";
import { queryLogistics } from "@/api/api"; // 假设 queryLogistics 是你的接口方法

export default {
  name: "LogisticsList",
  data() {
    return {
      orderId: "",
      logisticsId: "",
      logisticsSteps: [],
    };
  },
  mounted() {
    const { orderId, expressCode } = this.$route.query;
    if (orderId && expressCode) {
      this.orderId = orderId;
      this.logisticsId = expressCode;
      this.fetchLogisticsData(expressCode);
    } else {
      console.error("缺少必要的查询参数");
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    formatTime(time) {
      if (!time) return "";
      const date = new Date(time);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    },
    async fetchLogisticsData(expressCode) {
      showLoadingToast({
        message: "查询中...",
        forbidClick: true,
      });
      try {
        const response = await queryLogistics({ express_code: expressCode });
        if (response.code === 200) {
          const data = response.data;
          console.log("物流信息:", data);
          this.logisticsSteps = data.data || []; // 假设接口返回的物流步骤字段为 steps
          closeToast();
        } else {
          showFailToast("查询物流信息失败");
          console.error("查询物流信息失败:", response.message);
        }
      } catch (error) {
        console.error("请求出错:", error);
      }
    },
  },
};
</script>

<style scoped>
.logistics-list {
  padding: 10px;
}

.logistics-info {
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-id,
.logistics-id {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.van-steps {
  margin-top: 10px;
  text-align: left;
}

.scrollable-steps {
  max-height: 610px; /* 根据需要调整高度 */
  overflow-y: auto;
}
p {
  margin: 0;
}
h3 {
  margin: 0;
}
</style>
