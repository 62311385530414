<template>
  <div class="container">
    <h3>请输入客户订单或电话进行查询</h3>
    <van-cell-group>
      <van-field
        v-model="order"
        label="客户订单"
        placeholder="请输入客户订单号"
      />
    </van-cell-group>
    <van-cell-group>
      <van-field
        v-model="phone"
        label="客户电话"
        placeholder="请输入客户电话"
      />
    </van-cell-group>
    <van-button type="primary" size="large" @click="confirm">确认</van-button>
  </div>
</template>

<script>
import { showToast } from "vant";
import { queryUsers } from "@/api/api";

export default {
  name: "LoginPage",

  data() {
    return {
      order: "",
      phone: "",
    };
  },
  methods: {
    async confirm() {
      if (!this.order && !this.phone) {
        showToast({
          message: "请输入客户订单或电话",
        });
        return;
      }

      if (this.order && this.phone) {
        showToast({
          message: "订单和电话只需要输入一个",
        });
        return;
      }

      try {
        const params = {
          mobile: this.phone,
          order_id: this.order,
        };

        const response = await queryUsers(params);
        console.log("Response:", response);
        if (response.code == 200) {
          // 存储用户信息到本地缓存
          localStorage.setItem("userInfo", JSON.stringify(response.data));

          // 跳转到 home 页面
          this.$router.push("/");

          // 显示查询成功的提示
          showToast({
            message: "查询成功",
          });
        } else {
          // 处理失败情况
          showToast({
            message: "查询失败：用户不存在",
          });
        }
      } catch (error) {
        // 处理网络错误或其他异常
        showToast({
          message: "请求出错，请稍后再试",
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  padding: 100px 20px 20px 20px;
}
.van-cell-group {
  margin: 30px 0;
}
.van-button {
  margin: 0 auto 0;
  display: block;
}
</style>
