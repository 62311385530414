// src/api/axiosInstance.js
import { showToast } from 'vant';
import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://oa-b.yuchan.cn', // 替换为你的API基础URL
  timeout: 5000, // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在请求头中添加 token
    const token = localStorage.getItem('token'); // 假设 token 存储在 localStorage 中
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      // 处理非 200 的情况
      showToast({ message: data.message || '请求失败' });
      return Promise.reject(data);
    }
  },
  error => {
    // 处理请求错误
    showToast({ message: '网络请求错误' });
    return Promise.reject(error);
  }
);

export default instance;