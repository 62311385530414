<template>
  <div class="container">
    <div class="combined-section">
      <div class="user-center">
        <van-row>
          <van-col span="18" class="user-info">
            <van-badge>
              <div class="info-row" style="margin-bottom: 10px">
                <div class="info-value">
                  {{ customerName || source + "客户" }}
                </div>
              </div>
              <template #content>
                {{ source }}
              </template>
            </van-badge>
            <div class="info-row">
              <div class="info-value">{{ customerPhone || "暂无电话" }}</div>
            </div>
          </van-col>
          <van-col span="6" class="edit-buttons" @click="showEditPopup">
            <van-icon name="setting-o" size="26" />
          </van-col>
        </van-row>
      </div>
      <hr class="divider" />
      <div class="data-cards">
        <van-row justify="space-around">
          <van-col span="8">
            <div class="label">待发货</div>
            <div class="value">{{ unpaidCount }}</div>
          </van-col>
          <van-col span="8">
            <div class="label">待签收</div>
            <div class="value">{{ shippedCount }}</div>
          </van-col>
          <van-col span="8">
            <div class="label">已签收</div>
            <div class="value">{{ receivedCount }}</div>
          </van-col>
          <!-- <van-col span="6">
            <div class="label">有售后</div>
            <div class="value">{{ returningCount }}</div>
          </van-col> -->
        </van-row>
      </div>
    </div>
    <div style="margin: 10px">
      <van-row justify="space-around">
        <van-col span="15">
          <van-search
            v-model="searchValue"
            placeholder="请输入订单号搜索"
            @search="onSearch"
          />
        </van-col>
        <van-col span="3.5">
          <van-button type="primary" size="small" @click="onSearch"
            >搜索</van-button
          >
        </van-col>
        <van-col span="3.5">
          <van-button type="success" size="small" @click="refreshOrders"
            >刷新</van-button
          >
        </van-col>
      </van-row>
      <div style="margin-top: 5px">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="order in orders" :key="order.id">
            <template #title>
              <div class="order-title">
                <span v-if="order.mark" class="product-name">
                  <van-badge>
                    <span>{{
                      order.goods
                        ? order.goods
                            .map((good) => good.oln_item_name)
                            .join(" | ")
                        : "暂无商品"
                    }}</span>
                    <template #content>
                      {{ order.mark }}
                    </template>
                  </van-badge>
                </span>
                <span v-else class="product-name">
                  <span>{{
                    order.goods.map((good) => good.oln_item_name).join(" | ")
                  }}</span>
                </span>
                <span class="order-status">{{
                  statusMap[order.oln_status] || "其他"
                }}</span>
              </div>
              <hr class="separator" />
            </template>
            <template #label>
              <div class="order-details">
                <div class="left-details">
                  <div>店铺名:{{ order.shop_name }}</div>
                  <div>
                    订单号:
                    <span v-if="order.order_id.includes('|')">
                      {{ order.order_id.split("|")[0] }}
                      <br />
                      {{ order.order_id.split("|")[1] }}
                    </span>
                    <span v-else>
                      {{ order.order_id }}
                    </span>
                  </div>
                  <div>
                    <van-row>
                      <van-col span="12">
                        实际支付{{ order.paid_fee }}元
                      </van-col>
                      <van-col span="12">
                        <span v-if="order.has_refund" style="color: red"
                          >有售后单</span
                        >
                        <span v-else style="color: #07c160">无售后单</span>
                      </van-col>
                    </van-row>
                  </div>
                </div>
              </div>
              <div class="order-timings">
                <van-row>
                  <van-col
                    span="12"
                    v-if="order.pay_time"
                    class="custom-van-grid-item"
                    >下单: {{ formatTime(order.pay_time) }}</van-col
                  >
                  <van-col
                    span="12"
                    v-if="order.send_time"
                    class="custom-van-grid-item"
                    >发货: {{ formatTime(order.send_time) }}</van-col
                  >
                </van-row>
              </div>

              <div v-if="order.express_code || order.has_refund">
                <hr class="separator" />
                <van-row justify="space-around">
                  <van-col span="12">
                    <van-button
                      class="van-buttons"
                      v-if="order.express_code"
                      type="primary"
                      @click="viewLogistics(order)"
                    >
                      查看物流
                    </van-button>
                  </van-col>
                  <van-col span="12">
                    <van-button
                      class="van-buttons"
                      v-if="order.has_refund"
                      type="warning"
                      @click="viewAfterSale(order)"
                    >
                      查看售后单
                    </van-button>
                  </van-col>
                </van-row>
              </div>
            </template>
          </van-cell>
        </van-list>
      </div>
    </div>

    <!-- 编辑用户信息弹窗 -->
    <van-popup
      v-model:show="editPopupShow"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="popup-content">
        <h2>编辑用户信息</h2>
        <van-field
          v-model="customerName1"
          label="客户姓名"
          placeholder="请输入客户姓名"
        />
        <van-field
          v-model="customerPhone1"
          label="客户电话"
          placeholder="请输入客户电话"
        />
        <van-row justify="space-around">
          <van-col span="8">
            <van-button class="popup-buttons" type="default" @click="cancelEdit"
              >取消</van-button
            >
          </van-col>
          <van-col span="8">
            <van-button
              class="popup-buttons"
              type="primary"
              @click="confirmEdit"
              >确定</van-button
            >
          </van-col>
        </van-row>
        <van-button type="danger" class="logout-button" @click="logout"
          >退出登录</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { showFailToast, showToast, showLoadingToast, closeToast } from "vant";
import { queryUserUpdate, queryOrderList } from "@/api/api";

export default {
  name: "HomePage",
  data() {
    return {
      source: "京东",
      user: {},
      customerName: "张三",
      customerName1: "",
      customerPhone: "12345678901",
      customerPhone1: "",
      searchValue: "",
      orders: [],
      loading: false,
      finished: false,
      unpaidCount: 0,
      shippedCount: 0,
      receivedCount: 0,
      returningCount: 0,
      editPopupShow: false,
      noUser: false,
      buyerId: 0,
      currentPage: 1, // 当前页码
      totalCount: 0, // 总订单数量
      statusMap: {
        0: "未建交易",
        1: "等待付款",
        2: "等待发货",
        3: "已完成",
        4: "已关闭",
        5: "等待签收",
        6: "已签收",
      },
    };
  },
  methods: {
    checkUserInfo() {
      const userInfo = localStorage.getItem("userInfo");
      if (!userInfo) {
        this.$router.push("/login");
      } else {
        const user = JSON.parse(userInfo);
        this.user = user;
        this.customerName = user.real_name;
        this.customerPhone = user.mobile;
        this.buyerId = user.buyer_id;
      }
    },
    showEditPopup() {
      this.customerName1 = this.customerName;
      this.customerPhone1 = this.customerPhone;
      this.editPopupShow = true;
    },
    cancelEdit() {
      this.editPopupShow = false;
    },
    async confirmEdit() {
      try {
        const params = {
          buyer_id: this.user.buyer_id,
          real_name: this.customerName1,
          mobile: this.customerPhone1,
        };
        const response = await queryUserUpdate(params);
        if (response.code == 200) {
          showToast({
            message: "修改成功",
          });
          this.customerName = this.customerName1;
          this.customerPhone = this.customerPhone1;
          this.editPopupShow = false;
        } else {
          showToast({
            message: "修改失败",
          });
        }
      } catch (error) {
        showToast({
          message: "请求出错，请稍后再试",
        });
      }
    },
    logout() {
      localStorage.removeItem("userInfo");
      this.$router.push("/login");
    },
    onSearch() {
      this.orders = [];
      this.loading = false;
      this.finished = false;
      this.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      if (!this.loading && !this.finished) {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
          const user = JSON.parse(userInfo);
          this.user = user;
          this.customerName = user.real_name;
          this.customerPhone = user.mobile;
          this.buyerId = user.buyer_id;
          this.loadOrders();
        } else {
          this.$router.push("/login");
        }
      }
    },
    loadOrders() {
      if (!this.finished) {
        this.loading = true;
        this.queryOrderList();
      }
    },
    refreshOrders() {
      this.orders = [];
      this.loading = false;
      this.finished = false;
      this.currentPage = 1;
      this.searchValue = "";
      this.onLoad();
    },
    viewLogistics(order) {
      this.$router.push({
        path: "/logistics-list",
        query: { orderId: order.order_id, expressCode: order.express_code },
      });
    },
    formatTime(time) {
      if (!time) return "";
      const date = new Date(time);
      return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    },
    async queryOrderList() {
      showLoadingToast({
        message: "加载中...",
        forbidClick: true,
      });
      try {
        const params = {
          buyer_id: this.buyerId,
          page: this.currentPage,
          order_id: this.searchValue || undefined,
        };
        const response = await queryOrderList(params);
        if (response.code === 200) {
          this.orders = this.orders.concat(response.data);
          this.unpaidCount = response.awaiting_count;
          this.shippedCount = response.shipped_count;
          this.receivedCount = response.complete_count;
          this.returningCount = response.refund_count;
          this.totalCount = response.total_count;
          this.source = this.orders[0].from;
          closeToast();

          if (this.orders.length >= this.totalCount) {
            this.finished = true;
          }

          this.loading = false;
          this.currentPage++;
        } else {
          showFailToast("暂无订单");
        }
      } catch (error) {
        showToast({
          message: "请求出错，请稍后再试",
        });
      }
    },
    viewAfterSale(order) {
      this.$router.push({
        path: "/after-sale-detail",
        query: { orderId: order.order_id, tradeNo: order.trade_no },
      });
    },
  },
  mounted() {
    this.checkUserInfo();
  },
};
</script>

<style scoped>
.combined-section {
  background-color: #ffffff;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  padding: 0px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 15px 20px 0 20px;
}

.divider {
  border: none;
  border-top: 1px solid #ebedf0;
}

.container {
  text-align: center;
}

.user-center {
  background-color: #ffffff;
  padding: 15px 20px 5px 20px;
}

.user-info {
  text-align: left;
}

.info-row {
  display: flex;
  align-items: center;
}

.info-value {
  font-size: 16px;
  color: #666666;
}

.info-row:first-child .info-value {
  font-size: 20px;
  color: #333333;
}

.info-row:last-child .info-value {
  font-size: 14px;
  color: #666666;
}

.edit-buttons {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100%;
  padding-top: 15px;
}

.popup-content {
  padding: 20px;
}

.popup-content h2 {
  margin-bottom: 20px;
}

.popup-buttons {
  width: 100%;
  margin-top: 20px;
}

.logout-button {
  width: 90%;
  margin-top: 20px;
}

.data-cards {
  padding: 10px;
}

.label {
  font-size: 14px;
  color: #666;
}

.value {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.van-search {
  padding: 0 !important;
  margin: 0 !important;
}

.van-list {
  height: 560px;
  overflow-y: auto;
}

.order-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.product-name {
  width: 80%;
  font-weight: bold;
  text-align: left;
  font-size: 14px;
}

.order-status {
  color: #07c160;
  width: 20%;
  font-size: 14px;
}

.order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.left-details {
  font-size: 14px;
  color: #666;
  text-align: left;
  width: 100%;
}

.order-timings {
  font-size: 14px;
  color: #666;
}

.separator {
  border: none;
  border-top: 1px solid #ebedf0;
  margin: 10px 0;
}

.van-cell {
  border: 1px solid #ebedf0;
  margin: 10px;
  width: 94%;
}
</style>

<style>
.custom-van-grid-item {
  font-size: 13px;
  text-align: left;
}

.van-buttons {
  width: 80%;
  height: 30px !important;
}
</style>
