<template>
  <div class="order-list">
    <van-nav-bar
      title="历史订单列表"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />

    <van-card class="data-card">
      <template #desc>
        <van-row justify="space-around">
          <van-col span="8">
            <div class="label">待发货订单</div>
            <div class="value">{{ unpaidCount }}</div>
          </van-col>
          <van-col span="8">
            <div class="label">待签收订单</div>
            <div class="value">{{ shippedCount }}</div>
          </van-col>
          <van-col span="8">
            <div class="label">已签收订单</div>
            <div class="value">{{ receivedCount }}</div>
          </van-col>
        </van-row>
        <van-row justify="space-around" style="margin-top: 20px">
          <van-col span="8">
            <div class="label">退货中订单</div>
            <div class="value">{{ returningCount }}</div>
          </van-col>
          <van-col span="8">
            <div class="label">退货成功订单</div>
            <div class="value">{{ returnedCount }}</div>
          </van-col>
        </van-row>
      </template>
    </van-card>

    <van-row justify="space-around">
      <van-col span="16">
        <van-search
          v-model="searchValue"
          placeholder="请输入订单号搜索"
          @search="onSearch"
        />
      </van-col>
      <van-col span="3">
        <van-button type="primary" size="small" @click="onSearch"
          >搜索</van-button
        >
      </van-col>
      <van-col span="3">
        <van-button type="success" size="small" @click="refreshOrders"
          >刷新</van-button
        >
      </van-col>
    </van-row>
    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="order in orders" :key="order.id">
          <template #title>
            <div class="order-title">
              <span class="product-name">{{ order.productName }}</span>
              <span class="order-status">{{ order.status }}</span>
            </div>
          </template>
          <template #label>
            <div class="order-details">
              <div class="left-details">
                <div>订单号: {{ order.id }}</div>
                <div>
                  数量: {{ order.quantity }} 支付价格: {{ order.price }}
                </div>
              </div>
              <div class="right-details">
                <van-button
                  type="primary"
                  size="small"
                  @click="viewLogistics(order)"
                  >查看物流</van-button
                >
              </div>
            </div>
            <div class="order-timings">
              <van-grid :border="false" :column-num="2">
                <van-grid-item class="custom-van-grid-item">
                  付款时间: {{ formatTime(order.orderTime) }}
                </van-grid-item>
                <van-grid-item class="custom-van-grid-item">
                  发货时间: {{ formatTime(order.orderTime) }}
                </van-grid-item>
                <!-- <van-grid-item class="custom-van-grid-item">
                  签收时间: {{ formatTime(order.orderTime) }}
                </van-grid-item>
                <van-grid-item class="custom-van-grid-item">
                  退货时间: {{ formatTime(order.orderTime) }}
                </van-grid-item> -->
                <van-grid-item class="custom-van-grid-item">
                  结束时间: {{ formatTime(order.orderTime) }}
                </van-grid-item>
              </van-grid>
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
//   import { Toast } from 'vant';

export default {
  name: "OrderList",
  data() {
    return {
      searchValue: "",
      orders: [],
      loading: false,
      finished: false,
      unpaidCount: 0,
      shippedCount: 0,
      receivedCount: 0,
      returningCount: 0,
      returnedCount: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    onSearch() {
      // 模拟异步请求
      setTimeout(() => {
        this.orders = [
          {
            id: 1,
            productName: "商品A",
            quantity: 1,
            price: 100,
            status: "已支付",
            orderTime: "2023-10-01T10:00:00",
            shippingTime: "",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 2,
            productName: "商品B",
            quantity: 2,
            price: 200,
            status: "已发货",
            orderTime: "2023-10-02T11:00:00",
            shippingTime: "2023-10-03T12:00:00",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 1,
            productName: "商品A",
            quantity: 1,
            price: 100,
            status: "已支付",
            orderTime: "2023-10-01T10:00:00",
            shippingTime: "",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 2,
            productName: "商品B",
            quantity: 2,
            price: 200,
            status: "已发货",
            orderTime: "2023-10-02T11:00:00",
            shippingTime: "2023-10-03T12:00:00",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 1,
            productName: "商品A",
            quantity: 1,
            price: 100,
            status: "已支付",
            orderTime: "2023-10-01T10:00:00",
            shippingTime: "",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 2,
            productName: "商品B",
            quantity: 2,
            price: 200,
            status: "已发货",
            orderTime: "2023-10-02T11:00:00",
            shippingTime: "2023-10-03T12:00:00",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 1,
            productName: "商品A",
            quantity: 1,
            price: 100,
            status: "已支付",
            orderTime: "2023-10-01T10:00:00",
            shippingTime: "",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 2,
            productName: "商品B",
            quantity: 2,
            price: 200,
            status: "已发货",
            orderTime: "2023-10-02T11:00:00",
            shippingTime: "2023-10-03T12:00:00",
            receivedTime: "",
            returningTime: "",
            returnedTime: "",
          },
          // 其他订单...
        ];
      }, 1000);
    },
    onLoad() {
      // 模拟异步请求
      setTimeout(() => {
        this.orders.push(
          {
            id: 3,
            productName: "商品C",
            quantity: 3,
            price: 300,
            status: "已签收",
            orderTime: "2023-10-04T13:00:00",
            shippingTime: "2023-10-05T14:00:00",
            receivedTime: "2023-10-06T15:00:00",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 4,
            productName: "商品D",
            quantity: 4,
            price: 400,
            status: "退货中",
            orderTime: "2023-10-07T16:00:00",
            shippingTime: "2023-10-08T17:00:00",
            receivedTime: "2023-10-09T18:00:00",
            returningTime: "2023-10-10T19:00:00",
            returnedTime: "",
          },
          {
            id: 5,
            productName: "商品E",
            quantity: 5,
            price: 500,
            status: "退货成功",
            orderTime: "2023-10-11T20:00:00",
            shippingTime: "2023-10-12T21:00:00",
            receivedTime: "2023-10-13T22:00:00",
            returningTime: "2023-10-14T23:00:00",
            returnedTime: "2023-10-15T00:00:00",
          },
          {
            id: 3,
            productName: "商品C",
            quantity: 3,
            price: 300,
            status: "已签收",
            orderTime: "2023-10-04T13:00:00",
            shippingTime: "2023-10-05T14:00:00",
            receivedTime: "2023-10-06T15:00:00",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 4,
            productName: "商品D",
            quantity: 4,
            price: 400,
            status: "退货中",
            orderTime: "2023-10-07T16:00:00",
            shippingTime: "2023-10-08T17:00:00",
            receivedTime: "2023-10-09T18:00:00",
            returningTime: "2023-10-10T19:00:00",
            returnedTime: "",
          },
          {
            id: 5,
            productName: "商品E",
            quantity: 5,
            price: 500,
            status: "退货成功",
            orderTime: "2023-10-11T20:00:00",
            shippingTime: "2023-10-12T21:00:00",
            receivedTime: "2023-10-13T22:00:00",
            returningTime: "2023-10-14T23:00:00",
            returnedTime: "2023-10-15T00:00:00",
          },
          {
            id: 3,
            productName: "商品C",
            quantity: 3,
            price: 300,
            status: "已签收",
            orderTime: "2023-10-04T13:00:00",
            shippingTime: "2023-10-05T14:00:00",
            receivedTime: "2023-10-06T15:00:00",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 4,
            productName: "商品D",
            quantity: 4,
            price: 400,
            status: "退货中",
            orderTime: "2023-10-07T16:00:00",
            shippingTime: "2023-10-08T17:00:00",
            receivedTime: "2023-10-09T18:00:00",
            returningTime: "2023-10-10T19:00:00",
            returnedTime: "",
          },
          {
            id: 5,
            productName: "商品E",
            quantity: 5,
            price: 500,
            status: "退货成功",
            orderTime: "2023-10-11T20:00:00",
            shippingTime: "2023-10-12T21:00:00",
            receivedTime: "2023-10-13T22:00:00",
            returningTime: "2023-10-14T23:00:00",
            returnedTime: "2023-10-15T00:00:00",
          },
          {
            id: 3,
            productName: "商品C",
            quantity: 3,
            price: 300,
            status: "已签收",
            orderTime: "2023-10-04T13:00:00",
            shippingTime: "2023-10-05T14:00:00",
            receivedTime: "2023-10-06T15:00:00",
            returningTime: "",
            returnedTime: "",
          },
          {
            id: 4,
            productName: "商品D",
            quantity: 4,
            price: 400,
            status: "退货中",
            orderTime: "2023-10-07T16:00:00",
            shippingTime: "2023-10-08T17:00:00",
            receivedTime: "2023-10-09T18:00:00",
            returningTime: "2023-10-10T19:00:00",
            returnedTime: "",
          },
          {
            id: 5,
            productName: "商品E",
            quantity: 5,
            price: 500,
            status: "退货成功",
            orderTime: "2023-10-11T20:00:00",
            shippingTime: "2023-10-12T21:00:00",
            receivedTime: "2023-10-13T22:00:00",
            returningTime: "2023-10-14T23:00:00",
            returnedTime: "2023-10-15T00:00:00",
          }
        );
        this.loading = false;
        if (this.orders.length >= 10) {
          this.finished = true;
        }
      }, 1000);
    },
    refreshOrders() {},
    viewLogistics() {
      // 查看物流逻辑
      this.$router.push("/logistics-list");
    },
    formatTime(time) {
      if (!time) return "";
      const date = new Date(time);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    },
  },
};
</script>
<style scoped>
.order-list {
  padding: 10px;
}

.data-card {
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.van-card__desc {
  text-align: center;
}

.label {
  font-size: 14px; /* 文字大小 */
  color: #666; /* 文字颜色 */
}

.value {
  font-size: 24px; /* 数字大小 */
  color: #333; /* 数字颜色 */
  font-weight: bold; /* 加粗数字 */
}

.van-search {
  margin: 10px 0;
}

.van-list {
  margin-top: 10px;
  height: 544px; /* 保持高度不变 */
  overflow-y: auto; /* 添加滚动条 */
}

.order-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.product-name {
  font-weight: bold;
}

.order-status {
  color: #07c160; /* 根据状态调整颜色 */
}

.order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.left-details {
  /* font-size: 14px; */
  color: #666;
  text-align: left;
}

.right-details {
  display: flex;
  align-items: center;
}

/* .order-timings {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
} */
.custom-van-grid-item .van-grid-item__content {
  padding: 0 !important;
  font-size: 12px;
}
.van-search {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
