<template>
  <div class="product-introduction">
    <van-row type="flex" justify="space-between" align="center">
      <van-col span="16">
        <div style="margin-left: 10px">
          <van-search
            v-model="searchValue"
            placeholder="输入店名/黑白名单/平台搜索"
            @search="onSearch"
          />
        </div>
      </van-col>
      <van-col span="8" class="buttons-container">
        <van-button type="primary" size="small" @click="onSearch"
          >搜索</van-button
        >
        <van-button type="success" size="small" @click="refreshProducts"
          >刷新</van-button
        >
      </van-col>
    </van-row>
    <van-notice-bar left-icon="volume-o" text="点击文字可直接复制" />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="product in products"
          :key="product.id"
          :name="product.id"
          :title="`${product.name} (${product.class})`"
          :title-class="getTitleClass(product.class)"
        >
          <div class="product-details">
            <van-row type="flex" align="center">
              <van-col span="12">
                <h4 v-if="product.platform" class="price-title">所属平台</h4>
              </van-col>
              <van-col span="12">
                <p
                  v-if="product.platform"
                  class="price-content"
                  @click="copyText(product.platform)"
                >
                  {{ product.platform }}
                </p>
              </van-col>
            </van-row>
            <van-row type="flex" align="center">
              <van-col span="12">
                <h4 v-if="product.class" class="price-title">黑白名单</h4>
              </van-col>
              <van-col span="12">
                <p
                  v-if="product.class"
                  class="price-content"
                  @click="copyText(product.class)"
                >
                  {{ product.class }}
                </p>
              </van-col>
            </van-row>
            <van-row type="flex" align="center">
              <van-col span="12">
                <h4 v-if="product.status" class="price-title">
                  续存/授权/状态
                </h4>
              </van-col>
              <van-col span="12">
                <p
                  v-if="product.status"
                  class="price-content"
                  @click="copyText(product.status)"
                >
                  {{ product.status }}
                </p>
              </van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-list>
  </div>
</template>

<script>
import {
  showSuccessToast,
  showFailToast,
  showLoadingToast,
  closeToast,
} from "vant";
import { queryShop } from "@/api/api.js";

export default {
  name: "ProductShop",
  data() {
    return {
      searchValue: "",
      products: [],
      loading: false,
      finished: false,
      activeNames: [],
      name: "",
      platform: "",
      class: "",
    };
  },
  methods: {
    onSearch() {
      this.products = [];
      this.loading = false;
      this.finished = false;
      this.setQueryParams();
      this.onLoad();
    },
    onLoad() {
      if (!this.loading && !this.finished) {
        this.loadProducts();
      }
    },
    async loadProducts() {
      showLoadingToast({
        message: "加载中...",
        forbidClick: true,
      });
      this.loading = true;
      try {
        // 调用queryShop接口
        const response = await queryShop({
          name: this.name,
          platform: this.platform,
          class: this.class,
        });
        this.products = this.products.concat(response.data);
        this.loading = false;
        this.finished = true;
      } catch (error) {
        showFailToast("加载产品失败");
        this.loading = false;
      }
      closeToast();
    },
    refreshProducts() {
      this.products = [];
      this.loading = false;
      this.finished = false;
      this.searchValue = "";
      this.setQueryParams();
      this.onLoad();
    },
    setQueryParams() {
      switch (this.searchValue) {
        case "京东":
        case "淘系":
        case "拼多多":
          this.platform = this.searchValue;
          this.name = "";
          this.class = "";
          break;
        case "白名单":
          this.class = 1;
          this.name = "";
          this.platform = "";
          break;
        case "灰名单":
          this.class = 2;
          this.name = "";
          this.platform = "";
          break;
        case "黑名单":
          this.class = 3;
          this.name = "";
          this.platform = "";
          break;
        default:
          this.name = this.searchValue;
          this.platform = "";
          this.class = "";
      }
    },
    addProduct() {
      // 实现新增产品的逻辑
      this.$router.push("/add-product");
    },
    viewDetails(product) {
      // 实现查看详情的逻辑
      this.$router.push({
        path: "/product-details",
        query: { productId: product.id },
      });
    },
    copyText(text) {
      const formattedText = text.replace(/<br>/g, "\n"); // 将 <br> 替换为 \n
      const textarea = document.createElement("textarea");
      textarea.value = formattedText;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      showSuccessToast("已复制到剪贴板");
    },
    getTitleClass(classValue) {
      if (classValue === "黑名单") {
        return "collapse_title_red"; // 黑名单
      } else if (classValue === "灰名单") {
        return "collapse_title_orange"; // 白名单
      }
      return ""; // 默认情况下不添加任何类
    },
  },
  mounted() {
    this.onLoad();
  },
};
</script>

<style scoped>
.product-introduction {
  padding: 10px 0 0 0;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
}

.product-details {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  /* margin-top: 10px; */
}

.product-details h4 {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #333;
  text-align: left;
}

.product-details p {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  text-align: left;
  /* margin-top: 0px; */
}

.van-notice-bar {
  margin: 10px 0;
}
.van-list {
  height: 680px;
}
</style>
<style>
.collapse_title_red {
  color: red;
}
.collapse_title_orange {
  color: #fbbd12;
}
</style>
