<template>
  <div class="product-introduction">
    <van-row type="flex" justify="space-between" align="center">
      <van-col span="16">
        <div style="margin-left: 10px">
          <van-search
            v-model="searchValue"
            placeholder="请输入产品名称搜索"
            @search="onSearch"
          />
        </div>
      </van-col>
      <van-col span="8" class="buttons-container">
        <van-button type="primary" size="small" @click="onSearch"
          >搜索</van-button
        >
        <van-button type="success" size="small" @click="refreshProducts"
          >刷新</van-button
        >
      </van-col>
    </van-row>
    <van-notice-bar left-icon="volume-o" text="点击文字可直接复制" />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="product in products"
          :key="product.id"
          :name="product.id"
          :title="product.name"
        >
          <div class="product-details">
            <h4 v-if="product.introduction">简介</h4>
            <p
              v-if="product.introduction"
              v-html="product.introduction.replace(/\n/g, '<br>')"
              @click="copyText(product.introduction)"
            ></p>
            <h4 v-if="product.quality">酒质</h4>
            <p
              v-if="product.quality"
              v-html="product.quality.replace(/\n/g, '<br>')"
              @click="copyText(product.quality)"
            ></p>
            <h4 v-if="product.specifications">规格信息</h4>
            <p
              v-if="product.specifications"
              v-html="product.specifications.replace(/\n/g, '<br>')"
              @click="copyText(product.specifications)"
            ></p>
            <h4 v-if="product.additionalInfo">附加说明</h4>
            <p
              v-if="product.additionalInfo"
              v-html="product.additionalInfo.replace(/\n/g, '<br>')"
              @click="copyText(product.additionalInfo)"
            ></p>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-list>
  </div>
</template>

<script>
import {
  showSuccessToast,
  showFailToast,
  showLoadingToast,
  closeToast,
} from "vant";
import { queryIntroduction } from "@/api/api.js";

export default {
  name: "ProductIntroduction",
  data() {
    return {
      searchValue: "",
      products: [],
      loading: false,
      finished: false,
      activeNames: [],
    };
  },
  methods: {
    onSearch() {
      this.products = [];
      this.loading = false;
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      if (!this.loading && !this.finished) {
        this.loadProducts();
      }
    },
    async loadProducts() {
      showLoadingToast({
        message: "加载中...",
        forbidClick: true,
      });
      this.loading = true;
      try {
        // 调用queryIntroduction接口
        const response = await queryIntroduction({
          name: this.searchValue,
        });
        this.products = this.products.concat(response.data);
        this.loading = false;
        this.finished = true;
      } catch (error) {
        showFailToast("加载产品失败");
        this.loading = false;
      }
      closeToast();
    },
    refreshProducts() {
      this.products = [];
      this.loading = false;
      this.finished = false;
      this.searchValue = "";
      this.onLoad();
    },
    addProduct() {
      // 实现新增产品的逻辑
      this.$router.push("/add-product");
    },
    viewDetails(product) {
      // 实现查看详情的逻辑
      this.$router.push({
        path: "/product-details",
        query: { productId: product.id },
      });
    },
    copyText(text) {
      const formattedText = text.replace(/<br>/g, "\n"); // 将 <br> 替换为 \n
      const textarea = document.createElement("textarea");
      textarea.value = formattedText;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      showSuccessToast("已复制到剪贴板");
    },
  },
  mounted() {
    this.onLoad();
  },
};
</script>

<style scoped>
.product-introduction {
  padding: 10px 0 0 0;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
}

.product-details {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  /* margin-top: 10px; */
}

.product-details h4 {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #333;
  text-align: left;
}

.product-details p {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  text-align: left;
  margin-top: 0px;
}

.van-notice-bar {
  margin: 10px 0;
}
.van-list {
  height: 680px;
}
</style>
<style>
.van-list {
  overflow-y: auto;
}
.van-search {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
