import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue';
import OrderList from '../views/OrderList.vue';
import LogisticsList from '../views/LogisticsList.vue';
import ProductIntroduction from '../views/ProductIntroduction.vue';
import ProductPrice from '../views/ProductPrice.vue';
import ProductShop from '../views/ProductShop.vue';
import AfterSaleDetail from '@/views/AfterSaleDetail.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/order-list',
    name: 'orderList',
    component: OrderList, // 新增这行
  },
  {
    path: '/logistics-list',
    name: 'logisticsList',
    component: LogisticsList, // 新增这行
  },
  {
    path: '/product-introduction', 
    name: 'productIntroduction',
    component: ProductIntroduction,
  }
  ,
  {
    path: '/product-price', 
    name: 'productPrice',
    component: ProductPrice,
  }
  ,
  {
    path: '/product-shop', 
    name: 'productShop',
    component: ProductShop,
  },
  {
    path: '/after-sale-detail',
    name: 'AfterSaleDetail',
    component: AfterSaleDetail,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router